import { User } from "firebase/auth"

export type SixclearUser = User & { organization: string }

export type SignUpFields = {
    name: string,
    email: string,
    password: string,
    organization: string
}

export type SignInFields = {
    email: string,
    password: string,
    user?: User
}

export type ResetPasswordFields = {
    email: string
};

export type ResetPasswordResponsePayload = {
    ok: boolean,
    errors: RequestErrors
}

export type ChangeEmailResponsePayload = ResetPasswordResponsePayload;

type RequestError = { message: string }
type RequestErrors = RequestError[]

export type SignupResponsePayload = {
    ok: boolean,
    token: string | null,
    errors: RequestErrors
}

export type SignInResponsePayload = {
    ok: boolean,
    token: string | null,
    errors: RequestErrors
}

export type SignoutResponsePayload = {
    success: boolean,
    session_id: string,
    email: string,
    message: string
}

export type PortalProduct = {
    id: string,
    name: string,
    zip_url: string,
    zip_password: string
}

export type PortalApplication = {
    id: string,
    name: string,
    url: string,
    course_details?: any,
    certificates_info?: any[]
    renew_form_url?: string
    buy_form_url?: string
}

export type Device = {
    id: number,
    name?: string,
    computerId?: string,
    activationCodes: {id: number}[]
}

export type UserSubscription = {
    id: number,
    type: 'TRIAL' | 'PAID' | 'GIOTA'
    startDate: string,
    endDate: string,
    userId: number,
    numberOfDevices: number,
    deviceUserSubscriptions: {id: number, device: Device}[]
}

export type ActivateDeviceRequestPayload = {
    userSubscriptionId: number,
    majorSoftwareVersionId: number,
    device: {
        id: number | undefined,
        name: string,
        computerId: string
    }
}

export type AccountData = {
    ok: boolean,
    errors: RequestErrors,
    user: SixclearUser,
    products: {
        fli_driver?: PortalProduct
    },
    applications: {
        certificates?: PortalApplication,
        training?: PortalApplication
    }
}

export type UserUpdateFields = {email: string} | {displayName: string, phoneNumber: string, organization: string}

export type JADELicensesData = {
    ok: boolean,
    errors: RequestErrors,
    userSubscriptions: UserSubscription[],
    majorSoftwareVersions: {id: number, number: number}[]
}

export type JADEDeviceActivationData = {
    ok: boolean,
    errors: RequestErrors,
    deviceWasCreated: boolean,
    device: {id: number, activationCodes: {id: number}[]},
    deviceUserSubscription: {id: number, device: JADEDeviceActivationData['device']} | undefined,
    activationCode: {id: number, code: string}
}

export type JADEDeviceUpdateData = {
    ok: boolean,
    errors: RequestErrors,
    device: Device
}

export type JADEDeviceArchiveData = {
    ok: boolean,
    errors: RequestErrors
}

export type JADEDownloadsData = {
    ok: boolean,
    errors: RequestErrors,
    majorSoftwareVersions: any[],
    softwareSupportAssets: any[]
}

export type JADEDownloadUrlData = {
    ok: boolean,
    errors: RequestErrors,
    downloadUrl: string,
    downloadFilename: string
}

export class API {

    public async signUp(fields: SignUpFields): Promise<SignupResponsePayload> {
        const response = await fetch('/api/auth/signup', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async signOut(): Promise<SignoutResponsePayload> {
        const response = await fetch('/api/auth/signout', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return await response.json();
    }

    public async signIn(fields: SignInFields): Promise<SignInResponsePayload> {
        const response = await fetch('/api/auth/signin', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async resetPassword(fields: ResetPasswordFields): Promise<ResetPasswordResponsePayload> {
        const response = await fetch('/api/auth/reset-password', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async changeEmail(email: string): Promise<ChangeEmailResponsePayload> {
        const response = await fetch('/api/auth/change-email', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email})
        });

        return await response.json();
    }

    public async fetchAccountData(): Promise<AccountData> {
        const response = await fetch('/api/account/get-account-data', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return await response.json();
    }

    public async updateAccountData(fields: UserUpdateFields): Promise<AccountData> {
        const response = await fetch('/api/account/update-account-data', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(fields)
        });

        return await response.json();
    }

    public async fetchJADELicensesData(): Promise<JADELicensesData> {
        const response = await fetch('/api/jade/get-user-subscriptions', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return await response.json();
    }

    public async addUserSubscription(): Promise<JADELicensesData> {
        const response = await fetch('/api/jade/add-user-subscription', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ type: 'TRIAL' })
        });

        return await response.json();
    }

    public async activateDevice(payload: ActivateDeviceRequestPayload): Promise<JADEDeviceActivationData> {
        const response = await fetch('/api/jade/activate-device', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        return await response.json();
    }

    public async updateDevice(payload: {deviceId: number, name: string}): Promise<JADEDeviceUpdateData> {
        const response = await fetch('/api/jade/update-device', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        return await response.json();
    }

    public async archiveDevice(payload: {userSubscriptionId: number, deviceId: number}): Promise<JADEDeviceArchiveData> {
        const response = await fetch('/api/jade/archive-device', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        return await response.json();
    }

    public async fetchJADEDownloadsData(): Promise<JADEDownloadsData> {
        const response = await fetch('/api/jade/get-downloads', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return await response.json();
    }

    public async getJADEDownloadUrl(params: {downloadableAssetId: number}): Promise<JADEDownloadUrlData> {
        const response = await fetch('/api/jade/get-download-url', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params)
        });

        return await response.json();
    }
}