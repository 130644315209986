import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import { setCookie, removeCookie } from 'typescript-cookie';

import { FirebaseApp, FirebaseOptions, FirebaseAppSettings, initializeApp } from "firebase/app";
import { Auth, User, connectAuthEmulator, getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import { API } from './api';
import { ErrorsComponent } from './components/ErrorsComponent';

export class Session {

    protected firebaseApp: FirebaseApp;
    protected firebaseAuth: Auth;
    protected api: API;

    protected errorsComponent: ErrorsComponent;

    protected loggedInUser: User | null;

    protected desktopContactButton: HTMLElement;
    protected desktopLogInButton: HTMLElement;
    protected desktopSignUpButton: HTMLElement;
    protected mobileLogInButton: HTMLElement;
    protected mobileSignUpButton: HTMLElement;

    protected desktopLoggedInIconContainer: HTMLElement;
    protected desktopLoggedInIcon: HTMLElement;
    protected desktopLoggedInMenuPopper: PopperInstance | undefined;
    protected desktopLoggedInMenuOverlay: HTMLElement;
    protected desktopLogoutButton: HTMLElement;
    protected desktopLoggedInMenu: HTMLElement;
    protected mobileLoggedInMenu: HTMLElement;
    protected mobileLogoutButton: HTMLElement;

    constructor(firebaseOptions: FirebaseOptions, firebaseAppSettings?: FirebaseAppSettings) {
        this.firebaseApp = initializeApp(firebaseOptions, firebaseAppSettings);
        this.firebaseAuth = getAuth(this.firebaseApp);
        this.api = new API();
        this.loggedInUser = null;

        this.errorsComponent = new ErrorsComponent();
        document.body.append(this.errorsComponent.getRoot());

        // query and cache buttons for mobile and desktop
        this.desktopContactButton = document.getElementById("contact-btn")!.children[0] as HTMLElement;
        this.desktopLogInButton = document.getElementById("login-btn")!.children[0] as HTMLElement;
        this.desktopSignUpButton = document.getElementById("signup-btn")!.children[0] as HTMLElement;
        this.mobileLogInButton = document.getElementById("mobile-login-btn") as HTMLElement;
        this.mobileSignUpButton = document.getElementById("mobile-signup-btn") as HTMLElement;

        // create logged in dropdown button
        this.desktopLoggedInIconContainer = document.createElement('li');
        this.desktopLoggedInIconContainer.innerHTML = `
            <a id="logged-in-icon-btn" style="display:none;">
                <button type="button" class="btn btn-rounded btn-success"><i class="fa fa-user"></i>&nbsp;&nbsp;<i class="fas fa-angle-down"></i></button>
            </a>
        `;
        // query for subelement and cache
        this.desktopLoggedInIcon = this.desktopLoggedInIconContainer.children[0] as HTMLElement;

        // create logged in dropdown button
        this.desktopLoggedInMenu = document.createElement('div');
        this.desktopLoggedInMenu.classList.add('dropdown-menu');
        this.desktopLoggedInMenu.innerHTML = `
            <div id="arrow" data-popper-arrow></div>
            <h6 id="logged-in-menu-display-name" class="dropdown-header">Sixclear Account</h6>
            <a class="dropdown-item" href="/views/account/main">My Account</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" id="desktop-logout" href="#">Logout&nbsp;&nbsp;<i class="fas fa-sign-out-alt"></i></a>
        `;

        // create click overlay for handling dropdown close
        this.desktopLoggedInMenuOverlay = document.createElement('div');
        this.desktopLoggedInMenuOverlay.style.display = 'none';
        this.desktopLoggedInMenuOverlay.style.background = 'rgba(0, 0, 0, 0)';
        this.desktopLoggedInMenuOverlay.style.zIndex = '999';
        this.desktopLoggedInMenuOverlay.style.position = 'fixed';
        this.desktopLoggedInMenuOverlay.style.top = '0';
        this.desktopLoggedInMenuOverlay.style.right = '0';
        this.desktopLoggedInMenuOverlay.style.bottom = '0';
        this.desktopLoggedInMenuOverlay.style.left = '0';
        this.desktopLoggedInMenuOverlay.addEventListener('click', this.closeMenu.bind(this));

        // create mobile logged in menu
        this.mobileLoggedInMenu = document.createElement('li');
        this.mobileLoggedInMenu.classList.add('submenu');
        this.mobileLoggedInMenu.style.display = 'none';
        this.mobileLoggedInMenu.innerHTML = `
            <a id="mobile-logged-in-menu" class="menu-title text-success" href="#"><span id="mobile-logged-in-menu-display-name"></span>&nbsp;<i class="fa fa-user"></i></a>
            <ul>
                <li><a href="/views/account/main">My Account</a></li>
            </ul>
        `;

        this.mobileLogoutButton = document.createElement('li');
        this.mobileLogoutButton.style.display = 'none';
        this.mobileLogoutButton.id = 'mobile-logout-btn';
        this.mobileLogoutButton.innerHTML = `
            <a href="#" class="text-primary">Logout <i class="fas fa-sign-out-alt"></i>&nbsp;</a>
        `;

        // add elements to DOM
        this.desktopLogInButton.parentNode?.parentNode?.insertBefore(this.desktopLoggedInIconContainer, this.desktopSignUpButton.parentNode);
        this.desktopLogInButton.parentNode?.parentNode?.parentNode?.insertBefore(this.desktopLoggedInMenu, document.querySelector('.nav-tools'));
        this.mobileLogInButton.parentNode?.insertBefore(this.mobileLoggedInMenu, this.mobileLogInButton);
        this.mobileLogInButton.parentNode?.insertBefore(this.mobileLogoutButton, this.mobileLogInButton);
        document.body.appendChild(this.desktopLoggedInMenuOverlay);

        // call site JS functions needed to register events against our generated DOM
        // @ts-ignore - this is needed to register side-nav menu accordian on the account menu
        window.asideNavSubmenus('#mobile-logged-in-menu');

        // query for logout buttons and add signout click binding
        this.desktopLogoutButton = document.getElementById('desktop-logout') as HTMLElement;
        this.desktopLogoutButton.addEventListener('click', this.signOut.bind(this));
        this.mobileLogoutButton.addEventListener('click', this.signOut.bind(this));

        // firebase has a convention that a project id that starts with demo does not use
        // real firebase resources, which is what we want during local dev using the emulator
        if (firebaseOptions.projectId?.startsWith('demo')) {
            // the only emulator we need to activate for client code is the auth emulator
            connectAuthEmulator(this.firebaseAuth, "http://localhost:9099");
        }

        onAuthStateChanged(this.firebaseAuth, async (user: User | null) => {
            if (user !== null) {
                this.loggedInUser = user;
                this.setCookie();
                this.renderLoggedIn();
            }
            else {
                this.loggedInUser = null;
                removeCookie('__session', {
                    domain: ".sixclear.com",
                    path: "/"
                });
                this.renderLoggedOut();
            }
        });
    }

    public async setCookie() {
        if (this.loggedInUser) {
            const token = await this.loggedInUser.getIdToken(true);

            // this cookie is used by sixclear.com
            setCookie('__session', token, {
                domain: ".sixclear.com",
                // chrome recently changed behavior and only allows cookies to be set to expire 400 days in the future
                // https://developer.chrome.com/blog/cookie-max-age-expires/
                expires: new Date(Date.now() + (400 * 86400000)),
                path: "/"
            });

            // this cookie is used by portal
            setCookie('session_id', this.loggedInUser.uid, {
                domain: ".sixclear.com",
                // chrome recently changed behavior and only allows cookies to be set to expire 400 days in the future
                // https://developer.chrome.com/blog/cookie-max-age-expires/
                expires: new Date(Date.now() + (400 * 86400000)),
                path: "/"
            });

        }
    }

    public getFirebaseApp() {
        return this.firebaseApp;
    }

    public getFirebaseAuth() {
        return this.firebaseAuth;
    }

    public getAPI() {
        return this.api;
    }

    public getLoggedInUser() {
        return this.loggedInUser;
    }

    public addErrors(errors: {message: string}[]) {
        const existingErrors = this.errorsComponent.getTypedData('errors');

        this.errorsComponent.setTypedData('errors', existingErrors.concat(errors), 'replace');
    }

    public clearErrors() {
        this.errorsComponent.setTypedData('errors', [], 'replace');
    }

    public async signOut() {
        try {
            removeCookie('__session', {
                domain: ".sixclear.com",
                path: "/"
            });
            removeCookie('session_id', {
                domain: ".sixclear.com",
                path: "/"
            });
            await signOut(this.firebaseAuth);
            await this.sendPortalSignOut();
            window.location.href = '/views/auth/signin';
        } catch (error) {
            this.addErrors([{message: 'An unknown error occurred and you could not be signed out. Please try again.'}])
        }
    }

    public async sendPortalSignOut() {
        // to sign out the portal session we need to hit an endpoint from the server,
        // so send api request to do it
        try {
            await this.api.signOut();
        }
        catch (error) {
            console.error(error);
        }
    }

    protected renderLoggedIn() {
        this.desktopLoggedInIcon.style.display = 'table-cell';
        this.desktopContactButton.style.display = 'table-cell';
        this.desktopLogInButton.style.display = 'none';
        this.desktopSignUpButton.style.display = 'none';

        this.mobileLoggedInMenu.style.display = 'block';
        this.mobileLogoutButton.style.display = 'block';
        this.mobileLogInButton.style.display = 'none';
        this.mobileSignUpButton.style.display = 'none';

        const dropdownDisplayNameElement = document.getElementById('logged-in-menu-display-name');
        if (dropdownDisplayNameElement) {
            dropdownDisplayNameElement.innerHTML = this.loggedInUser?.displayName as string;
        }

        const mobileDisplayNameElement = document.getElementById('mobile-logged-in-menu-display-name');
        if (mobileDisplayNameElement) {
            mobileDisplayNameElement.innerHTML = this.loggedInUser?.displayName as string;
        }

        this.desktopLoggedInIcon.addEventListener('click', () => {
            if (this.desktopLoggedInMenuPopper === undefined) {
                this.desktopLoggedInMenu.style.display = 'block';
                this.desktopLoggedInMenuOverlay.style.display = 'block';
                this.desktopLoggedInMenuPopper = createPopper(this.desktopLoggedInIconContainer, this.desktopLoggedInMenu, {
                    placement: 'bottom-end',
                    modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-10, -10],
                          },
                        },
                      ],
                });
            }
            else {
                this.closeMenu();
            }

        });

    }

    protected renderLoggedOut() {
        this.desktopContactButton.style.display = 'table-cell';
        this.desktopLogInButton.style.display = 'table-cell';
        this.desktopSignUpButton.style.display = 'table-cell';

        this.mobileLoggedInMenu.style.display = 'none';
        this.mobileLogoutButton.style.display = 'none';
        this.mobileLogInButton.style.display = 'block';
        this.mobileSignUpButton.style.display = 'block';
    }

    protected closeMenu() {
        this.desktopLoggedInMenuPopper?.destroy();
        this.desktopLoggedInMenuPopper = undefined;
        this.desktopLoggedInMenuOverlay.style.display = 'none';
        this.desktopLoggedInMenu.style.display = 'none';
    }

}

export function init(firebaseOptions: ConstructorParameters<typeof Session>[0], firebaseAppSettings: ConstructorParameters<typeof Session>[1]): Session | void {
    try {
        return new Session(firebaseOptions, firebaseAppSettings);
    } catch (error) {
        console.error(error);
    }
}