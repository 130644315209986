import { ComponentEventDataMap, TypedDataComponent } from "@subgraph-io/edge/component";
import { DOM, RootElement } from "@subgraph-io/edge-ui/dom/DOM";

type ErrorsComponentStoredData = {
    errors: {
        replace: {message: string}[]
    }
}

type ErrorsComponentEventMap = ComponentEventDataMap & {}

export class ErrorsComponent extends TypedDataComponent<ErrorsComponentStoredData, ErrorsComponentEventMap> implements RootElement {

    protected root: DOM;

    constructor() {
        super();
        this.root = new DOM('div');

        this.setTypedData('errors', [], 'replace');

        this.root.setStyles({
            "position": 'fixed',
            "display": 'flex',
            "align-items": 'center',
            "flex-direction": 'column',
            "justify-content": 'flex-start',
            "top": '70px',
            "width": '100%',
            "margin": '0 auto',
            "z-index": 9999
        });

        this.root.registerForEvent('.remove-error', 'click', this.onRemoveErrorClicked, this);
        this.root.registerForEvent('.clear-errors', 'click', this.onClearErrors, this);

        this.registerForEvent('DATA_UPDATE', 'errors', this.onErrorsChange, this);
    }

    protected onErrorsChange() {
        const errors = this.getTypedData('errors');
        const errorCardEls = [];

        this.root.empty();

        for (let i = 0; i < errors.length; i++) {
            const error = errors[i];
            const errorCardEl = DOM.createHTMLElement('div');

            DOM.setAttribute('data-index', i.toString(), errorCardEl);
            errorCardEl.classList.add('alert', 'alert-warning');
            DOM.setStyles({
                "padding": '3px 8px',
                "margin-bottom": '.5rem',
                "display": 'flex',
                "align-items": 'center',
                "justify-content": 'space-between'
            }, errorCardEl);
            errorCardEl.innerHTML = `
                <span>${error.message}</span>
                <div class="remove-error" style="margin-left: 10px; cursor: pointer;">
                    <i class="fa fa-times"></i>
                </div>
            `;

            errorCardEls.push(errorCardEl);
        }

        if (errors.length > 0) {
            const clearAllEl = DOM.createHTMLElement('div');

            clearAllEl.classList.add('btn', 'btn-sm', 'btn-rounded', 'btn-danger', 'clear-errors');
            clearAllEl.innerHTML = `
                <span>Clear all <i class="fa fa-times"></i></span>
            `;
            errorCardEls.push(clearAllEl);
        }

        this.root.append(errorCardEls);

    }

    protected onRemoveErrorClicked(event: any) {
        const errors = this.getTypedData('errors');
        const target = event.eventData.target! as HTMLElement;
        const cardEl = target.closest('[data-index]');
        const indexToRemove = DOM.getAttribute('data-index', cardEl!)!;

        errors.splice(parseInt(indexToRemove), 1);
        this.setTypedData('errors', errors, 'replace');

    }

    protected onClearErrors() {
        this.setTypedData('errors', [], 'replace');
    }

    getRoot() {
        return this.root.getRoot();
    }

}